
export const environment = {
  production: true,

  // test sever 1
  apiUrl: 'https://api-beta2.squirx.com/api',

    // test sever 2
    // apiUrl: 'https://demo2-api.squirx.com/api',
};


