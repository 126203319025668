<p-toast key="menu" position="top-right"></p-toast>

<div class="layout-topbar">
  <div class="layout-topbar-wrapper">
    <div class="layout-topbar-left">
      <div class="layout-topbar-logo-wrapper">
        <a href="#" class="layout-topbar-logo">
          <img style="    image-rendering: -moz-crisp-edges;
          image-rendering: -o-crisp-edges;
          image-rendering: -webkit-optimize-contrast;
          -ms-interpolation-mode: nearest-neighbor;" src="assets/img/logo7.png" alt="mirage-layout" />
        </a>
      </div>

      <a href="#" class="sidebar-menu-button" (click)="app.onMenuButtonClick($event)">
        <i class="pi pi-bars"></i>
      </a>


      <a href="#" class="megamenu-mobile-button" (click)="app.onMegaSellMenuMobileButtonClick($event)">
        <i class="pi pi-align-right megamenu-icon"></i>
      </a>

      <a href="#" class="topbar-menu-mobile-button" (click)="app.onTopbarMobileMenuButtonClick($event)">
        <i class="pi pi-ellipsis-v"></i>
      </a>




      <!-- <button  pButton (click)="app.onMegaSellMenuButtonClick($event)" type="button" label="Sell"
                class="p-button-info p-mr-5 custom-button shdow"></button> -->
      <!-- <ul class="layout-megamenu" [ngClass]="{'layout-megamenu-active fadeInDown': app.megaSellMenuActive}"
            (click)="app.onMegaSellMenuClick($event)">
          <li [ngClass]="{'active-topmenuitem': activeItem === 1}" (click)="mobileMegaSellMenuItemClick(1)">
            <ul>
              <li class="active-row ">
                <i  class="pi pi-circle-on"></i>
                <span><h5  routerLink="customers/orders" (click)="app.onMegaSellMenuButtonClick($event)" >Orders</h5></span>
              </li>
              <li class="active-row ">
                <i class="pi pi-circle-on"></i>
                <span><h5 routerLink="customers/quotes"(click)="app.onMegaSellMenuButtonClick($event)" >Quotes</h5></span>
              </li>
            </ul>
          </li>
        </ul> -->


      <button pButton routerLink="sell/quotes" (click)='clearEmitte()' type="button" label='{{"QUOTES" | translate}}'
        class="p-button-secondary p-mr-5 custom-button shdow"></button>
      <div class="layout-megamenu-wrapper">
        <button pButton routerLink="sell/orders" (click)='clearEmitte()' type="button"
          label='{{"SELL/INVOICE" | translate}}' class="p-button-info p-mr-5 custom-button shdow"></button>
      </div>
      <div class="layout-megamenu-wrapper">
        <button pButton routerLink="buy/purchaseorders" (click)='clearEmitte()' type="button"
          label='{{"BUY/PO" | translate}}' class=" p-mr-5 custom-button shdow"></button>
      </div>
      <!-- <div class="layout-megamenu-wrapper">
        <button pButton routerLink="suppliers/purchaseorders" type="button" label="Purchase Order"
          class="p-button-danger p-mr-5 custom-button shdow"></button>
      </div> -->

      <!-- <button pButton (click)="app.onMegaBuyMenuButtonClick($event)" type="button" label="Buy"
                class="p-button-danger p-mr-5 custom-button shdow"></button>
        <ul class="layout-megamenu" [ngClass]="{'layout-megamenu-active fadeInDown': app.megaBuyMenuActive}"
            (click)="app.onMegaBuyMenuClick($event)">
          <li [ngClass]="{'active-topmenuitem': activeItem === 1}" (click)="mobileMegaSellMenuItemClick(1)">
            <ul>
              <li class="active-row ">
                <i class="pi pi-circle-on"></i>
                <span><h5 routerLink="suppliers/purchaseorders" (click)="app.onMegaBuyMenuButtonClick($event)" >Orders</h5></span>
              </li>
            </ul>
          </li>
        </ul> -->
      <!--        <button pButton type="button" label="Cash Position" class="ui-button-secondary"-->
      <!--                style="margin-right: 15px;"></button>-->
      <!--        <button pButton type="button" label="Product" class="ui-button-rounded p-mr-2"></button>-->
      <!--        <button pButton type="button" label="Order" class="ui-button-rounded p-mr-2"></button>-->
      <!--        <button pButton type="button" label="Supplier" class="ui-button-rounded p-mr-2"></button>-->
      <!--        <button pButton type="button" label="Customer" class="ui-button-rounded"></button>-->

    </div>
    <div class="layout-topbar-right fadeInDown">
      <ul class="layout-topbar-actions">
        <!-- <li #search class="search-item topbar-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === search}">
          <a href="#" class="topbar-search-mobile-button" (click)="app.onTopbarItemClick($event,search)">
            <i class="topbar-icon pi pi-search"></i>
          </a>
          <ul class="search-item-submenu fadeInDown" (click)="app.topbarItemClick = true">
            <li>
              <span class="md-inputfield search-input-wrapper">
                <p-autoComplete [(ngModel)]="serachItem" [suggestions]="filteredsections" field="name"
                  (onSelect)="onSelectsection($event)" (completeMethod)="filter($event)" placeholder='{{"SEARCH" | translate}}'>
                  <ng-template let-item let-i="index" pTemplate="item">
                    <span>{{item.name}}</span>

                  </ng-template>

                </p-autoComplete>

                <i class="pi pi-search"></i>
              </span>
            </li>
          </ul>
        </li> -->
        <li #lang class="topbar-item  " [ngClass]="{'active-topmenuitem': app.activeTopbarItem === lang}">
          <a class="p-pb-3" href="#" (click)="app.onTopbarItemClick($event,lang)" style="cursor: pointer;">
            <i style="font-weight: 600; font-style: normal" class="topbar-icon">{{currentLang?.lang}}</i>
          </a>

          <ul class="fadeInDown" style="min-width:10vw !important;">
            <li class="layout-submenu-header">
              <h1 style="font-size: 20px;
              font-weight: 600;">{{"LANG" | translate}}</h1>

            </li>
            <li class="deals">

              <ul>
                <li style="padding:0px !important ;" *ngFor="let item of langs" (click)="langChanged(item)">

                  <div style="min-width:10vw !important;" class="p-grid p-p-3 " >

                    <div class="p-col-9 "><label class="menu-text"
                        style="font-size: 1.05rem; cursor: pointer;">{{item.value}} </label></div>
                  </div>
                </li>

              </ul>
            </li>
          </ul>
        </li>
        <li #message class="topbar-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === message}">
          <a href="#" (click)="app.onTopbarItemClick($event,message)">
            <i *ngIf="todayEvents.length" class="topbar-icon far fa-calendar-alt" pBadge
              [value]="todayEvents.length"></i>
            <i *ngIf="!todayEvents.length" class="topbar-icon far fa-calendar-alt"></i>

          </a>
          <ul style=" min-width: 200px !important; " class="fadeInDown">
            <li class="layout-submenu-header">
              <h1 style="font-size: 20px;
              font-weight: 600;">{{"CALENDER" | translate}}</h1>
              <span style="color: #c3ccdd;
              font-weight: 600;">{{"TODAY_HAAVE" | translate}} {{todayEvents.length}} {{"EVENT" | translate}}</span>
            </li>
            <li *ngFor="let item of todayEvents" class=" p-d-block layout-submenu-item">
              <div class="menu-text">
                <div (click)="showEvent(item)" class="p-grid">
                  <div class="p-col-5 ">
                    <p style=" color: var(--primary-color) !important; font-weight: 600;">{{item.startTime}}</p>
                  </div>
                  <div style="text-align: left;" class="p-col-7">
                    <p style="font-weight: 600;" class="text-break">{{item.title}} </p>

                  </div>
                </div>
              </div>
            </li>
            <li class=" p-d-block layout-submenu-item">
              <div [routerLink]="['/erp/calendar']" routerLinkActive="router-link-active"
                style="position: relative; color: var(--primary-color) ;" class="menu-text text-center">

                <i style="position: absolute;top: 12%;
                  left: 10%;" class=" far fa-calendar-alt"></i>
                {{"OPEN_CAL" | translate}}
              </div>
            </li>
            <!-- <li class="layout-submenu-item">
              <img src="assets/layout/images/topbar/avatar-tatiana.png" alt="mirage-layout" width="35" />
              <div class="menu-text">
                <p>The holistic world view</p>
                <span>Tatiana Gagelman</span>
              </div>
              <i class="pi pi-angle-right"></i>
            </li> -->
          </ul>
        </li>

        <!-- <li #gift class="topbar-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === gift}">
          <a href="#" (click)="app.onTopbarItemClick($event,gift)">
            <i class="topbar-icon pi pi-envelope"></i>
          </a>
          <ul class="fadeInDown">
            <li class="layout-submenu-header">
              <h1>Deals</h1>
            </li>

            <li class="deals">
              <ul>
                <li>
                  <img src="assets/layout/images/topbar/deal-icon-sapphire.png" alt="mirage-layout" width="35" />
                  <div class="menu-text">
                    <p>Sapphire</p>
                    <span>Angular</span>
                  </div>
                  <i class="pi pi-angle-right"></i>
                </li>
                <li>
                  <img src="assets/layout/images/topbar/deal-icon-roma.png" alt="mirage-layout" width="35" />
                  <div class="menu-text">
                    <p>Roma</p>
                    <span>Minimalism</span>
                  </div>
                  <i class="pi pi-angle-right"></i>
                </li>
                <li>
                  <img src="assets/layout/images/topbar/deal-icon-babylon.png" alt="mirage-layout" width="35" />
                  <div class="menu-text">
                    <p>Babylon</p>
                    <span>Powerful</span>
                  </div>
                  <i class="pi pi-angle-right"></i>
                </li>
              </ul>
              <ul>
                <li>
                  <img src="assets/layout/images/topbar/deal-icon-harmony.png" alt="mirage-layout" width="35" />
                  <div class="menu-text">
                    <p>Harmony</p>
                    <span>USWDS</span>
                  </div>
                  <i class="pi pi-angle-right"></i>
                </li>
                <li>
                  <img src="assets/layout/images/topbar/deal-icon-prestige.png" alt="mirage-layout" width="35" />
                  <div class="menu-text">
                    <p>Prestige</p>
                    <span>Elegancy</span>
                  </div>
                  <i class="pi pi-angle-right"></i>
                </li>
                <li>
                  <img src="assets/layout/images/topbar/deal-icon-ultima.png" alt="mirage-layout" width="35" />
                  <div class="menu-text">
                    <p>Ultima</p>
                    <span>Material</span>
                  </div>
                  <i class="pi pi-angle-right"></i>
                </li>
              </ul>
            </li>
          </ul>
        </li> -->




        <li (click)="openConfig()" style="cursor:pointer" class="topbar-item">
          <a><i class="pi pi-cog"></i> </a>
        </li>

        <li #profile class="topbar-item profile-item"
          [ngClass]="{'active-topmenuitem': app.activeTopbarItem === profile}">
          <a href="#" (click)="app.onTopbarItemClick($event,profile)">
            <span class="profile-image-wrapper">
              <img *ngIf="currentuser?.image" [src]="currentuser?.image" alt="mirage-layout"
              class="avatar" />
              <img *ngIf="!currentuser?.image" src="assets/layout/images/Profile_avatar.png" alt="Avatar" class="avatar">

            </span>
            <span class="profile-info-wrapper">
              <h3 class="info-name">{{currentuser?.first_name}}</h3>
            </span>
          </a>
          <ul class="profile-item-submenu fadeInDown">
            <li class="  p-d-block profile-submenu-header">

              <div class="  p-grid">
                <div class="p-col-4 profile-menu">
                  <h3>{{currentuser?.first_name}}</h3>
                  <!-- <span>Ceo</span> -->
                </div>
                <div style="text-align: right;" class="p-col-8">
                  <img *ngIf="!currentuser?.image" src="assets/layout/images/Profile_avatar.png" alt="Avatar" class="avatar">

                  <img *ngIf="currentuser?.image" [src]="currentuser?.image" class="avatar" alt="mirage-layout" width="60" />
                </div>
              </div>
            </li>
            <!-- <li class="layout-submenu-item">
              <i class="pi pi-list icon icon-1"></i>
              <div class="menu-text">
                <p>Tasks</p>
                <span>3 open issues</span>
              </div>
              <i class="pi pi-angle-right"></i>
            </li>
            <li class="layout-submenu-item">
              <i class="pi pi-shopping-cart icon icon-2"></i>
              <div class="menu-text">
                <p>Payments</p>
                <span>24 new</span>
              </div>
              <i class="pi pi-angle-right"></i>
            </li>
            <li class="layout-submenu-item">
              <i class="pi pi-users icon icon-3"></i>
              <div class="menu-text">
                <p>Clients</p>
                <span>+80%</span>
              </div>
              <i class="pi pi-angle-right"></i>
            </li> -->
            <li class="layout-submenu-footer">
              <button (click)="signOut()" class="signout-button">{{"SIGN_OUT" | translate}}</button>
              <button (click)="account_dialog=true" class="signout-button">{{"ACCOUNT" | translate}}</button>

            </li>
          </ul>
        </li>
        <!-- <li>
          <a href="#" class="layout-rightpanel-button" (click)="app.onRightPanelButtonClick($event)">
            <i class="pi pi-arrow-left"></i>
          </a>
        </li> -->

      </ul>

      <ul class="profile-mobile-wrapper">
        <li #mobileProfile class="topbar-item profile-item"
          [ngClass]="{'active-topmenuitem': app.activeTopbarItem === mobileProfile}">
          <a href="#" (click)="app.onTopbarItemClick($event,mobileProfile)">
            <span class="profile-image-wrapper">
              <img src="assets/img/logo72.svg" alt="mirage-layout" />
            </span>
            <span class="profile-info-wrapper">
              <h3>{{currentuser?.first_name}}</h3>
              <span>Design</span>
            </span>
          </a>
          <ul class="fadeInDown">

            <!-- <li>
              <i class="pi pi-list icon icon-1"></i>
              <div class="menu-text">
                <p>Tasks</p>
                <span>3 open issues</span>
              </div>
              <i class="pi pi-angle-right"></i>
            </li>
            <li>
              <i class="pi pi-shopping-cart icon icon-2"></i>
              <div class="menu-text">
                <p>Payments</p>
                <span>24 new</span>
              </div>
              <i class="pi pi-angle-right"></i>
            </li>
            <li>
              <i class="pi pi-users icon icon-3"></i>
              <div class="menu-text">
                <p>Clients</p>
                <span>+80%</span>
              </div>
              <i class="pi pi-angle-right"></i>
            </li> -->
            <li class="layout-submenu-footer">
              <button (click)="signOut()" class="signout-button">{{"SIGN_OUT" | translate}}</button>
              <button (click)="account_dialog=true" class="signout-button">{{"ACCOUNT" | translate}}</button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>


<p-dialog modal="true" (onShow)="outside_Status(true)" (onHide)="outside_Status(false)" [resizable]="false"
  [draggable]="false" class="p-shadow-1" [header]="eventDialogHeader" [(visible)]="eventDialog"
  [style]="{width: '30rem'}">
  <div class="p-grid">

    <div class="p-grid p-col-12 p-pt-4">
      <div class="p-col-6">
        <span class="p-float-label ">
          <p-calendar dateFormat="dd-mm-yy" (ngModelChange)="notempty($event)" appendTo="body" showIcon="true"
            [(ngModel)]="selected_event.start" [required]="true" ngDefaultControl></p-calendar>
          <label for="title-input">{{"START_DATE" | translate}}</label>
        </span>
      </div>
      <div class="p-col-6">
        <span class="p-float-label ">
          <p-calendar [(ngModel)]="selected_event.startTime" (ngModelChange)="notempty($event)" [timeOnly]="true"
            ngDefaultControl showTime="true" hourFormat="12"></p-calendar>

          <label for="title-input">{{"TIME" | translate}}</label>
        </span>
      </div>

    </div>

    <div class="p-grid p-col-12 p-pt-4">
      <div class="p-col-6">
        <span class="p-float-label ">
          <p-calendar dateFormat="dd-mm-yy" (ngModelChange)="notempty($event)" appendTo="body" showIcon="true"
            [(ngModel)]="selected_event.end" [required]="true" ngDefaultControl></p-calendar>
          <label for="title-input">{{"END_DATE" | translate}}</label>
        </span>
      </div>
      <div class="p-col-6">
        <span class="p-float-label ">
          <p-calendar [(ngModel)]="selected_event.endTime" (ngModelChange)="notempty($event)" [timeOnly]="true"
            ngDefaultControl showTime="true" hourFormat="12"></p-calendar>

          <label for="title-input">{{"TIME" | translate}}</label>
        </span>
      </div>

    </div>
    <div class="p-col-12 p-pt-3">
      <span class="p-float-label ">
        <input [(ngModel)]="selected_event.title" (ngModelChange)="notempty($event)" id="title-input" type="text"
          pInputText>
        <label for="title-input">{{"TITLE" | translate}}</label>
      </span>

    </div>
    <div class="p-col-12 p-pt-4">

      <span class="p-float-label">
        <textarea [(ngModel)]="selected_event.description" (ngModelChange)="notempty($event)" id="description-input"
          rows="4" pInputTextarea></textarea>
        <label for="description-input">{{"DISCRIPTION" | translate}}</label>
      </span>
    </div>

  </div>


  <p-footer>

    <button type="button" pButton icon="fas fa-pen" (click)="updateEvent()"
      label=' {{"CUSTOMER_LIST_UPDATE" | translate}} '></button>
    <button type="button" pButton icon="fas fa-trash" class="p-button-danger" (click)="deleteEvent()"
      label=' {{"CUSTOMER_LIST_DELETE_BUTTON" | translate}} '></button>
    <button type="button" pButton icon="pi pi-times" (click)="eventDialog=false"
      label=' {{"CUSTOMER_LIST_CANACEL_BUTTON" | translate}} ' class="p-button-outlined p-button-secondary"></button>



  </p-footer>
</p-dialog>


<app-account-settings [visable]="account_dialog" (onHide)="onHideAccount($event)"></app-account-settings>
